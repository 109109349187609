<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea v">
			<div class="inner" style="padding-right:30px !important;">
				<h2 class="txtL v3" style="display:flex">
					[ 프로젝트 ] <span class="title">{{ this.name }}</span>
					<span>{{ `  [${this.beginDate} ~ ${this.endDate}]` }}</span>
				</h2>

				<a @click="moveBack()" class="btnBack">이전 페이지</a>
			</div>
		</div>
		<!--// titArea -->
		<!-- contents -->
		<div class="contents">
			<!-- memoList -->
			<div class="memoList">
				<ul v-if="total > 0">
					<li v-for="(memo, index) in projectMemoList" :key="index">
						<a @click="onMemo(memo.id)">
							<!-- location -->
							<div class="location">
								<span>{{ memo.buildingName }}</span>
								<span v-if="memo.floorId != null">{{ memo.floorName }}</span>
								<span v-if="memo.roomId != null">{{ memo.roomName }}</span>
								<span v-if="memo.objectPinId != null">{{ memo.objectName }}</span>
								<span v-if="memo.locations && memo.locations.length > 1" class="locationCount"
									>외 {{ memo.locations.length - 1 }}개</span
								>
							</div>
							<!--// location -->
							<!-- memo -->
							<div class="memo">
								<div class="user">
									<div class="imgArea">
										<p><img :src="`/file/${memo.creatorImageId}`" alt="" v-if="memo.creatorImageId" /></p>
									</div>
									<p>{{ memo.creatorName }}</p>
								</div>
								<p class="date">
									<span>{{ memo.createDate | dateFormatYear }}</span>
									<span>{{ memo.createDate | dateFormatMonth }}</span>
								</p>
								<p>
									<span>
										<pre v-if="memo.content" style="font-family: 'Noto Sans KR', sans-serif;">{{
											enterContent(memo.content).replace(/ /g, '&nbsp;')
										}}</pre>
									</span>
								</p>
							</div>
							<!--// memo -->
						</a>
						<!-- imgList -->
						<div class="devImgList">
							<div class="inBox">
								<p v-for="(memoFile, index) in memo.memoFiles" :key="index">
									<img :src="`/file/${memoFile.fileId}`" alt="" @click="onOpenImage(memoFile.fileId, memo.memoFiles)" />
								</p>
							</div>
						</div>
						<!--// imgList -->
						<!-- viewInfo -->
						<div class="viewInfo">
							<a class="btnComment">{{ memo.commentCount }}</a>
							<a class="btnViews">{{ memo.views }}</a>
						</div>
						<!--// viewInfo -->
					</li>
				</ul>
				<ul v-else>
					<h3>최신글</h3>
					<!-- noData -->
					<div class="noData">
						<p>프로젝트와 연동된 메모가 없습니다.</p>
					</div>
				</ul>
			</div>
			<!--// memoList -->
		</div>
		<!--// contents -->
		<PopUpImage ref="popUpImage"></PopUpImage>
	</div>

	<!--// container -->
</template>
<script>
import { dateFormatYear, dateFormatMonth } from '@/utils/cmm';
import PopUpImage from '../popup/popUpImage';
import project from '@/api/project';
import building from '@/api/building';
import { showSpinner, hideSpinner } from '@/utils/cmm.js';

export default {
	components: { PopUpImage },
	created() {},
	filters: {
		dateFormatYear,
		dateFormatMonth,
	},
	mounted() {
		if (!this.$route.params.id) {
			this.$router.go(-1);
			return false;
		}
		this.reload();

		this.$EventBus.$on('paging', () => {
			if (this.total <= this.offset) return;
			this.onGetProjectMemo();
		});
	},
	beforeDestroy() {
		this.$EventBus.$off('paging');
	},
	data() {
		return {
			buildingId: this.$route.params.buildingId,
			projectId: this.$route.params.id,
			name: null,
			beginDate: null,
			endDate: null,
			total: 0,
			offset: 0,
			limit: 10,
			projectMemoList: [],
		};
	},
	methods: {
		moveBack() {
			this.$router.push({ name: 'projectList', params: { buildingId: this.$route.params.buildingId } });
		},
		async onGetProjectMemo() {
			await this.onGetProject();
			let params = {
				offset: this.offset,
				limit: this.limit,
				projectId: this.projectId,
			};
			try {
				let res = await project.getProjectMemoList(params);
				this.total = res.total;
				if (this.total > 0) {
					res.data.forEach(p => this.projectMemoList.push(p));
					this.offset += this.limit;
				}
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
		},
		async onGetProject() {
			let res = await project.getOne(this.projectId);
			this.name = res.name;
			this.beginDate = res.beginDate;
			this.endDate = res.endDate;
		},
		enterContent(content) {
			let count = content.match(/\n/g);
			if (count != null && count.length > 4) {
				let tmp = '';
				for (let i = 0; i < 5; i++) {
					tmp += content.split('\n')[i] + '\n';
				}
				content = tmp.substring(0, tmp.length - 1);
			}

			return content;
		},
		onOpenImage(imageId, memoFiles) {
			this.$refs.popUpImage.onOpenImage(imageId, memoFiles);
		},
		onMemo(id) {
			this.$router.push({
				name: 'memoDetail',
				params: {
					id: id,
					buildingId: this.$route.params.buildingId,
					projectId: this.projectId,
					name: this.name,
					reqUrl: 'projectMemoList',
				},
			});
		},
		async reload() {
			try {
				showSpinner();
				await building.getOne(this.buildingId);
				this.onGetProjectMemo();
			} catch (ex) {
				await this.$alert(`건물 상세조회 에러가 발생했습니다.\r\n(${ex.message})`);
				return this.$router.go(-1);
			} finally {
				hideSpinner();
			}
		},
	},
};
</script>
<style scoped>
.locationCount {
	padding-left: 5px;
	background: none;
}
.txtL.v3 span {
	white-space: nowrap;
}
.txtL.v3 .title {
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
