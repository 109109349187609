// import axios from 'axios';
import axiosApi from '@/api/index';

let urlPath = '/api/project';

async function create(params) {
	return axiosApi.post(`${urlPath}`, params);
}

async function get(params) {
	return axiosApi.get(`${urlPath}`, { params });
}

async function getOne(id) {
	return axiosApi.get(`${urlPath}/${id}`);
}

async function getSchedule(params) {
	return axiosApi.get(`${urlPath}/schedule`, { params });
}

async function getProjectMemoList(params) {
	return axiosApi.get(`${urlPath}/projectMemoList`, { params });
}

async function getProjectStartList(params) {
	return axiosApi.get(`${urlPath}/ProjectStartList`, { params });
}

async function update(params) {
	return axiosApi.put(`${urlPath}/`, params);
}

async function remove(id) {
	return axiosApi.delete(`${urlPath}/${id}`);
}
export default { create, get, getOne, getSchedule, getProjectMemoList, getProjectStartList, update, remove };
